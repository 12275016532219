var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.organizationType != 'Awaris' ? _c('div', [_c('h5', {
    staticClass: "mb-3"
  }, [_vm._v(_vm._s(_vm.$t("user.update-org")) + " - " + _vm._s(_vm.user.name))])]) : _vm._e(), _vm.organizationType == 'Awaris' ? _c('div', [_c('h5', {
    staticClass: "mb-3"
  }, [_vm._v(_vm._s(_vm.$t("user.update-awaris")))])]) : _vm._e(), _vm.organizationType == 'Awaris' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-8",
    class: {
      'has-error': _vm.$v.form.name.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "name"
    }
  }, [_vm._v(_vm._s(_vm.$t("name")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.name.$model,
      expression: "$v.form.name.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "name",
      "required": ""
    },
    domProps: {
      "value": _vm.$v.form.name.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.name, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.name.$error && !_vm.$v.form.name.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.name")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "role"
    }
  }, [_vm._v(_vm._s(_vm.$t("position")))]), _vm.userRoleVal == 'Staff' ? _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.role,
      expression: "form.role"
    }],
    staticClass: "form-control",
    attrs: {
      "name": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.form, "role", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, _vm._l(_vm.roles, function (role) {
    return _c('option', {
      key: role,
      domProps: {
        "value": role
      }
    }, [_vm._v(" " + _vm._s(role) + " ")]);
  }), 0) : _vm._e(), _vm.userRoleVal != 'Staff' ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.form.role) + " ")]) : _vm._e()])])]) : _vm._e(), _vm.organizationType != 'Awaris' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-12",
    class: {
      'has-error': _vm.$v.form.name.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "name"
    }
  }, [_vm._v(_vm._s(_vm.$t("name")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.name.$model,
      expression: "$v.form.name.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "name",
      "required": ""
    },
    domProps: {
      "value": _vm.$v.form.name.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.name, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.name.$error && !_vm.$v.form.name.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.name")) + " ")]) : _vm._e()])]) : _vm._e(), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-3",
    class: {
      'has-error': _vm.$v.form.gender.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "gender"
    }
  }, [_vm._v(_vm._s(_vm.$t("gender")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.gender.$model,
      expression: "$v.form.gender.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": "gender"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.form.gender, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.gender")) + " --")]), _vm._l(_vm.genders, function (gender, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": gender.split(':')[0]
      }
    }, [_vm._v(" " + _vm._s(gender.split(":")[1]) + " ")]);
  })], 2), _vm.$v.form.gender.$error && !_vm.$v.form.gender.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("gender")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group col-md-3",
    class: {
      'has-error': _vm.$v.form.ic_number.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "ic_number"
    }
  }, [_vm._v(_vm._s(_vm.$t("mykad")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('the-mask', {
    staticClass: "form-control",
    attrs: {
      "id": "ic_number",
      "mask": ['######-##-####']
    },
    model: {
      value: _vm.$v.form.ic_number.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.form.ic_number, "$model", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "$v.form.ic_number.$model"
    }
  }), _vm.$v.form.ic_number.$error && !_vm.$v.form.ic_number.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.mykad")) + " ")]) : _vm._e()], 1), _c('div', {
    staticClass: "form-group col-md-3"
  }, [_c('label', {
    attrs: {
      "for": "office_phone_number"
    }
  }, [_vm._v(_vm._s(_vm.$t("office-no")))]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.form.office_phone_number,
      expression: "form.office_phone_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "office_phone_number"
    },
    domProps: {
      "value": _vm.form.office_phone_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "office_phone_number", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group col-md-3",
    class: {
      'has-error': _vm.$v.form.mobile_number.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "mobile_number"
    }
  }, [_vm._v(_vm._s(_vm.$t("mobile-no")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.mobile_number.$model,
      expression: "$v.form.mobile_number.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "mobile_number"
    },
    domProps: {
      "value": _vm.$v.form.mobile_number.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.mobile_number, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.mobile_number.$error && !_vm.$v.form.mobile_number.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.mobile-no")) + " ")]) : _vm._e(), _vm.$v.form.mobile_number.$error && !_vm.$v.form.mobile_number.numeric ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.mobile-in-number")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group col-md-12",
    class: {
      'has-error': _vm.$v.form.address.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "address"
    }
  }, [_vm._v(_vm._s(_vm.$t("address")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.address.$model,
      expression: "$v.form.address.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "address"
    },
    domProps: {
      "value": _vm.$v.form.address.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.address, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.address.$error && !_vm.$v.form.address.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("address")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group col-md-4",
    class: {
      'has-error': _vm.$v.form.postcode.$error
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('label', {
    staticClass: "d-block",
    attrs: {
      "for": "postcode"
    }
  }, [_vm._v(_vm._s(_vm.$t("postcode")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('span', {
    staticStyle: {
      "text-decoration": "underline",
      "font-size": "11px",
      "color": "#333"
    },
    on: {
      "click": _vm.openpostcode
    }
  }, [_vm._v(_vm._s(_vm.$t("add-new")))])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.lazy.trim",
      value: _vm.$v.form.postcode.$model,
      expression: "$v.form.postcode.$model",
      modifiers: {
        "lazy": true,
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "postcode"
    },
    domProps: {
      "value": _vm.$v.form.postcode.$model
    },
    on: {
      "blur": [_vm.getPostcode, function ($event) {
        return _vm.$forceUpdate();
      }],
      "change": function ($event) {
        _vm.$set(_vm.$v.form.postcode, "$model", $event.target.value.trim());
      }
    }
  }), _vm.$v.form.postcode.$error && !_vm.$v.form.postcode.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.postcode")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    attrs: {
      "for": "city"
    }
  }, [_vm._v(_vm._s(_vm.$t("city")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.form.city,
      expression: "form.city"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "city",
      "readonly": ""
    },
    domProps: {
      "value": _vm.form.city
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "city", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("state")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.form.state,
      expression: "form.state"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "state",
      "readonly": ""
    },
    domProps: {
      "value": _vm.form.state
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "state", $event.target.value);
      }
    }
  })])]), _vm.organizationType == 'Awaris' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-4",
    class: {
      'has-error': _vm.$v.form.bank_name.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "bank_name"
    }
  }, [_vm._v(_vm._s(_vm.$t("bankname")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.bank_name.$model,
      expression: "$v.form.bank_name.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "bank_name"
    },
    domProps: {
      "value": _vm.$v.form.bank_name.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.bank_name, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.bank_name.$error && !_vm.$v.form.bank_name.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("bankname")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group col-md-4",
    class: {
      'has-error': _vm.$v.form.bank_account_number.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "bank_account_number"
    }
  }, [_vm._v(_vm._s(_vm.$t("bank-account")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.bank_account_number.$model,
      expression: "$v.form.bank_account_number.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "bank_account_number"
    },
    domProps: {
      "value": _vm.$v.form.bank_account_number.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.bank_account_number, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.bank_account_number.$error && !_vm.$v.form.bank_account_number.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("bank-account")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])]) : _vm._e(), _c('hr'), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-4",
    class: {
      'has-error': _vm.$v.form.status.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("status")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.status.$model,
      expression: "$v.form.status.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.form.status, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v(_vm._s(_vm.$t("active")))]), _c('option', {
    attrs: {
      "value": "2"
    }
  }, [_vm._v(_vm._s(_vm.$t("inactive")))])]), _vm.$v.form.status.$error && !_vm.$v.form.status.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" Status " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group col-md-4",
    class: {
      'has-error': _vm.$v.form.email.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("email")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.email.$model,
      expression: "$v.form.email.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "required": ""
    },
    domProps: {
      "value": _vm.$v.form.email.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.email, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), !_vm.$v.form.email.email ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.invalid-email")) + " ")]) : _vm._e(), _vm.$v.form.email.$error && !_vm.$v.form.email.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.email")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("username")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.username,
      expression: "form.username"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.username
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])) { return null; }
        $event.preventDefault();
      },
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "username", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("participated-inheritance-course")) + ":")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.form.is_attended_course,
      expression: "form.is_attended_course",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.form, "is_attended_course", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v(_vm._s(_vm.$t("yes")))]), _c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v(_vm._s(_vm.$t("no")))])])])]), _c('hr'), _vm.userRoleVal ? _c('div', [_c('h5', [_vm._v(_vm._s(_vm.$t("user.access")))]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    attrs: {
      "for": "branch_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("org.nav2")))]), _vm.userRoleVal == 'Staff' ? _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.form.branch_id,
      expression: "form.branch_id",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "branch_id",
      "id": "branch_id"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.form, "branch_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.branch")) + " --")]), _vm._l(_vm.branches, function (branch) {
    return _c('option', {
      key: branch.id,
      domProps: {
        "value": branch.id
      }
    }, [_vm._v(" " + _vm._s(branch.branch_name) + " ")]);
  })], 2) : _vm._e(), _vm.userRoleVal != 'Staff' ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.user_accesses.accessible.branch_name) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    attrs: {
      "for": "role"
    }
  }, [_vm._v(_vm._s(_vm.$t("user-role")))]), _vm.userRoleVal == 'Staff' ? _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.form.role,
      expression: "form.role",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "role",
      "id": "role"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.form, "role", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("role")) + " --")]), _vm._l(_vm.roles, function (role) {
    return _c('option', {
      key: role,
      domProps: {
        "value": role
      }
    }, [_vm._v(" " + _vm._s(role) + " ")]);
  })], 2) : _c('input', {
    staticClass: "form-control-plaintext",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.user_accesses.role
    }
  })]), _vm.organizationType != 'Awaris' ? _c('div', {
    staticClass: "form-group col-md-4"
  }, [_c('label', {
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("staff-id")))]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.form.staff_id,
      expression: "form.staff_id"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.form.staff_id
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "staff_id", $event.target.value);
      }
    }
  })]) : _vm._e()])]) : _vm._e(), _c('hr'), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.back
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]), _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button",
      "disabled": _vm.btnSave
    },
    on: {
      "click": _vm.submitForm
    }
  }, [_c('i', {
    staticClass: "mdi mdi-file-document"
  }), _vm._v(" " + _vm._s(_vm.$t("save")) + " "), _vm.isSubmit ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _vm._e()])]), _c('b-modal', {
    ref: "modalPostcode",
    staticClass: "modal fade",
    attrs: {
      "title": _vm.$t('add-newpostcode'),
      "ok-title": _vm.$t('save'),
      "cancel-title": _vm.$t('cancel')
    },
    on: {
      "ok": _vm.storePostcode
    }
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("postcode")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formPostcode.postcode,
      expression: "formPostcode.postcode"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.formPostcode.postcode
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.formPostcode, "postcode", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("city")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.formPostcode.city,
      expression: "formPostcode.city"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.formPostcode.city
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.formPostcode, "city", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("state")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.formPostcode.state,
      expression: "formPostcode.state"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.formPostcode.state
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.formPostcode, "state", $event.target.value);
      }
    }
  })])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }